.wrapper {
  position: absolute;
  width: 136px;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: transparent;
  mix-blend-mode: normal;
  cursor: pointer;
  z-index: 3;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

.wrapper-hover {
  position: absolute;
  width: 445px;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  mix-blend-mode: normal;
}

/* .menu-text {
  margin-top: 16px;
} */

/* .menu-text-gray {
  opacity: 0.4;
  margin-top: 16px;
} */

.menu-searchIconTranslucence {
  opacity: 0.5;
}

.menu-homeIconTranslucence {
  opacity: 0.5;
}

.menu-liveTVIconTranslucence {
  opacity: 0.5;
}

.menu-onDemandIconTranslucence {
  opacity: 0.5;
}

.menu-moreIconTranslucence {
  opacity: 0.5;
}

.menu-guideIconTranslucence {
  opacity: 0.5;
}

.RedShade {
  width: 136px;
  height: 120px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(237, 26, 61, 0.7) 0%, rgba(237, 26, 61, 0) 100%);
}

.RedShadePosition {
  height: 120px
}

/* .secondaryMenu-text {
  width: 300px;
  margin-top: 54px;
}

.secondaryMenu-textFocus {
  margin-top: 54px;
  width: 300px;
  color: #ED1A3D;
} */

/* .secondaryMenu-text:hover {
  color: #ED1A3D;
} */

.centerHorizontally {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconPosition {
  margin-top: 100px;
  position: relative;
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .wrapper {
    position: absolute;
    width: 7.08vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: transparent;
    mix-blend-mode: normal;
    cursor: pointer;
    z-index: 3;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }

  .wrapper-hover {
    position: absolute;
    width: 23.17vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    mix-blend-mode: normal;
  }

  .RedShade {
    width: 7.08vw;
    height: 6.24vw;
    background: radial-gradient(50% 50% at 50% 50%, rgba(237, 26, 61, 0.7) 0%, rgba(237, 26, 61, 0) 100%);
  }

  .RedShadePosition {
    height: 6.25vw
  }

  .iconPosition {
    margin-top: 5.2vw;
    position: relative;
  }
}

@media (min-width: 1023px) and (max-width: 1440px) {
  .wrapper {
    position: absolute;
    width: 102px;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: transparent;
    mix-blend-mode: normal;
    cursor: pointer;
    z-index: 3;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }

  .wrapper-hover {
    position: absolute;
    width: 334px;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    mix-blend-mode: normal;
  }

  .RedShade {
    width: 102px;
    height: 90px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(237, 26, 61, 0.7) 0%, rgba(237, 26, 61, 0) 100%);
  }

  .RedShadePosition {
    height: 90px
  }

  .iconPosition {
    margin-top: 3.125vh;
    position: relative;
  }
}