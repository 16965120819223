.moreIcon {
  margin-left: 36px;
  opacity: 0.5;
  position: absolute;
}

.moreIconWhite {
  margin-left: 36px;
  position: absolute;
}

.moreText {
  color: #FFF;
  font-family: Museo-700;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 96px;
  opacity: 0.5;
}

.moreTextWhite {
  color: #FFF;
  font-family: Museo-700;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 96px;
}

.triangleIcon {
  margin-left: 10px;
  margin-top: 5px;
}

.myCast {
  color: #FFDF00;
  font-family: Museo-700;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 95px;
  margin-top: 43px;
}

.myCastIcon {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #000;
}

.title {
  color: #FFF;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 35px;
}

.iconLocation {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 30px;
}

.settings {
  color: #00B9E3;
  font-family: Museo-700;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 95px;
  margin-top: 43px;
}

.remote {
  color: #E32490;
  font-family: Museo-700;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 95px;
  margin-top: 43px;
}

.help {
  color: #5EC6C9;
  font-family: Museo-700;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 95px;
  margin-top: 43px;
}

.line {
  width: 1px;
  background: rgba(255, 255, 255, 0.20);
  position: absolute;
  left: 45px;
  height: calc(100% - 24px);
}

.RedShade {
  width: 58px;
  height: 58px;
  position: absolute;
  top: -18px;
  left: 16px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(237, 26, 61, 0.7) 0%, rgba(237, 26, 61, 0) 100%);
}