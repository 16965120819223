.yellow {
  color: #FFDF00 !important;
}

.grey {
  color: #C0C2C4 !important;
}

.red {
  color: #ED1A3D !important;
}

.light-slate {
  color: #8589A1 !important;
}

.dark-slate {
  color: #1D3444 !important;
}

.epg-grey {
  color: #929495 !important;
}

.dark-grey {
  color: #757575 !important;
}

.powder-blue {
  color: #5EC6C9 !important;
}

.white {
  color: #FFFFFF;
}

.gray {
  color: #C0C2C4;
}
