.default {
  cursor: default;
  composes: white from '../../index.module.css';
  user-select: none;
}

.h1 {
  font-family: Museo-700;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: 88px;
  /* 125.714% */
}

.h2 {
  font-family: Museo-700;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  /* 100% */
}

.h3 {
  font-family: Museo-700;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 112% */
}

.h4 {
  font-family: Museo-700;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 124.444% */
}

.h5 {
  font-family: Museo-700;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  /* 120% */
}

.h6 {
  font-family: Museo-700;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 125% */
}

.h7 {
  font-family: Museo-700;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 114.286% */
}

.h8 {
  font-family: Museo-700;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */
}

.h9 {
  /* 📱 Mobile/🌝  Museo 700/24px */
  font-family: Museo-700;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 116.667% */
}

.l16 {
  font-family: Lato-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.l20 {
  font-family: Lato-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
}

.l24 {
  font-family: Lato-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
}

.lb24 {
  font-family: Lato-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
}

.lb28 {
  font-family: Lato-Bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 114.286% */
}

.l28 {
  font-family: Lato-Regular;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 114.286% */
}

.l32 {
  font-family: Lato-Medium;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  /* 133.333% */
}

.lb32 {
  font-family: Lato-Bold;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
}

.lb40 {
  font-family: Lato-Bold;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 120% */
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .h1 {
    font-size: 3.64vw;
    line-height: 4.58vw;
  }

  .h2 {
    font-size: 3.33vw;
    line-height: 3.33vw;
  }

  .h3 {
    font-size: 2.6vw;
    line-height: 2.91vw;
  }

  .h4 {
    font-size: 2.34vw;
    line-height: 2.91vw;
  }

  .h5 {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  .h6 {
    font-size: 1.66vw;
    line-height: 2vw;
  }

  .h7 {
    font-size: 1.45vw;
    line-height: 1.66vw;
  }

  .h8 {
    font-size: 0.83vw;
    line-height: 0.93vw;
  }

  .l16 {
    font-size: 0.83vw;
    line-height: 1.25vw;
  }

  .l20 {
    font-size: 1vw;
    line-height: 1.25vw;
  }

  .l24 {
    font-size: 1.25vw;
    line-height: 1.66vw;
  }

  .lb24 {
    font-size: 1.25vw;
    line-height: 1.66vw;
  }

  .lb28 {
    font-size: 1.45vw;
    line-height: 1.66vw;
  }

  .l28 {
    font-size: 1.45vw;
    line-height: 1.66vw;
  }

  .l32 {
    font-size: 1.56vw;
    line-height: 2vw;
  }

  .lb32 {
    font-size: 1.66vw;
    line-height: 2vw;
  }

  .lb40 {
    font-size: 2vw;
    line-height: 2.5vw;
  }
}

@media (min-width: 1023px) and (max-width: 1440px) {
  .h2 {
    font-size: 48px;
    line-height: 48px;
  }

  .h3 {
    font-size: 36px;
    line-height: 42px;
  }

  .h4 {
    font-size: 32px;
    line-height: 40px;
  }

  .h5 {
    font-size: 28px;
    line-height: 36px;
  }

  .h6 {
    font-size: 24px;
    line-height: 28px;
  }

  .h7 {
    font-size: 20px;
    line-height: 24px;
  }

  .l16 {
    font-size: 12px;
    line-height: 18px;
  }

  .l20 {
    font-size: 14px;
    line-height: 18px;
  }

  .lb24,
  .l24 {
    font-size: 18px;
    line-height: 24px;
  }

  .lb28,
  .l28 {
    font-size: 20px;
    line-height: 24px;
  }

  .lb32,
  .l32 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .h2 {
    font-size: 32px;
    line-height: 36px;
  }

  .h3 {
    font-size: 28px;
    font-weight: 32px;
  }

  .h4 {
    font-size: 28px;
    font-weight: 32px;
  }

  .h5 {
    font-size: 28px;
    font-weight: 32px;
  }

  .h6 {
    font-size: 24px;
    font-weight: 28px;
  }

  .h7 {
    font-size: 20px;
    line-height: 24px;
  }

  .lb16,
  .l16 {
    font-size: 14px;
    line-height: 16px;
  }

  .lb20,
  .l20 {
    font-size: 16px;
    line-height: 20px;
  }

  .lb24,
  .l24 {
    font-size: 16px;
    line-height: 20px;
  }

  .lb28,
  .l28 {
    font-size: 20px;
    line-height: 24px;
  }

  .lb32,
  .l32 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 769px) {
  .h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .h4 {
    font-size: 24px;
    line-height: 28px;
  }

  .h5 {
    font-size: 24px;
    font-weight: 28px;
  }

  .h6 {
    font-size: 20px;
    line-height: 24px;
  }

  .h7 {
    font-size: 18px;
    line-height: 32px;
  }

  .lb16,
  .l16 {
    font-size: 12px;
    line-height: 16px;
  }

  .lb20,
  .l20 {
    font-size: 14px;
    line-height: 16px;
  }

  .lb24,
  .l24 {
    font-size: 14px;
    line-height: 16px;
  }

  .lb28,
  .l28 {
    font-size: 16px;
    line-height: 20px;
  }

  .lb32,
  .l32 {
    font-size: 16px;
    line-height: 24px;
  }
}
