body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
  content: 
    url('../assets/images/bg_details_new.png')
    url('../assets/images/bg_generic.png')
    url('../assets/images/bg_home_new.jpg')
    url('../assets/images/bg_mycast.png')
    url('../assets/images/bg_setting.png')
    url('../assets/images/bg_welcome.png')
    url('../assets/images/bg_guide.png');
}

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
}

.page-wrapper {
  height: 100%;
  width: 100%;
}

.page {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.page::-webkit-scrollbar {
  display: none;
}

.page.login-mode {
  padding-left: 0;
}

.scroll-hidden {
  overflow: hidden;
}

@media (max-width: 1023px) {
  .page {
    height: calc(100% - 105px);
  }

  .page.mobile-full {
    height: 100%;
  }
}
