body {
  margin: 0;
  background: black;
}

p {
  margin: 0;
}

img {
  display: block;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@font-face {
  font-family: Lato-Regular;
  src: url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Lato-Bold;
  src: url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: Lato-Medium;
  src: url(./assets/fonts/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Museo-700;
  src: url(./assets/fonts/Museo700.otf) format('opentype');
}

